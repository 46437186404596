import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {deleteCompanyUser, loadCompanyUsers} from "../../actions/account";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Button, Col, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, MailOutlined, UnorderedListOutlined, UserAddOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";
import CompanyUserPermissionsModal from "./CompanyUserPermissionsModal";
import CompanyUserInvitationModal from "./CompanyUserInvitationModal";
import CompanyUserCreationModal from "./CompanyUserCreationModal";

class CompanyUsers extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        users: [],
        loadingUsers: false,

        showInviteUserModal: false,
        showCreateUserModal: false,

        showUserModal: false,
        selectedUser: null,
    };

    componentDidMount() {
        this.props.updatePage('Users');
        this.loadUsers();
    }

    loadUsers(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingUsers: true });

        this.props.loadCompanyUsers(this.props.companyGuid, page, per_page,(res) => {
            this.setState({ loadingUsers: false, users: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingUsers: true, users: [] });
            }
        });
    }

    deleteUser(guid) {
        this.props.deleteCompanyUser(this.props.companyGuid, guid, () => {
            message.success('User successfully deleted!');
            this.loadUsers();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingUsers: true, users: [] });
            }
        });
    }


    render() {
        const columns = [
            { title: 'Name', render: (item, record) => record.user.first_name + ' ' + record.user.last_name },
            { title: 'Email', render: (item, record) => record.user.email },
            { title: 'Type', align: 'center', dataIndex: 'account_type', render: (item) => normalizeEnum(item)},
            { title: 'Added', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_company_users'])) {
            columns.push({ align: 'right', render: (item, record) => {

                    return <Space>
                        <Button size='small' icon={<UnorderedListOutlined />} onClick={() => this.setState({ selectedUser: record.user.guid, showUserModal: true })}>Details</Button>
                        <Popconfirm
                            title="Are you sure you wish to delete this user?"
                            onConfirm={() => this.deleteUser(record.user.guid)}
                            okText='Delete'
                            placement='left'
                            okButtonProps={{danger: true}}
                            cancelText='Cancel'>
                            <Tooltip title='Delete user'><Button size='small' danger icon={<DeleteOutlined />} /></Tooltip>
                        </Popconfirm>
                    </Space>
                }});
        }

        return(
            <AccessGate scopes={['can_view_company_users']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_company_users']}>
                            <Space>
                                <Button type='primary' icon={<MailOutlined/>}
                                        onClick={() => this.setState({showInviteUserModal: true})}>Invite Existing
                                    User</Button>
                                <Button type='primary' icon={<UserAddOutlined/>}
                                        onClick={() => this.setState({showCreateUserModal: true})}>Create New
                                    User</Button>
                            </Space>
                        </AccessItem>
                    </Col>
                    <Col><Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadUsers()}
                                                                                type='text'
                                                                                icon={<UndoOutlined/>}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        onChange={(pagination) => this.loadUsers(pagination.current, pagination.pageSize)}
                        dataSource={this.state.users}
                        columns={columns}
                        rowKey={(item) => item.user.guid}
                        pagination={this.state.users.length > this.state.pagination.pageSize ? this.state.pagination : false}
                        loading={this.state.loadingUsers}/>
                </div>

                    <CompanyUserInvitationModal
                        open={this.state.showInviteUserModal}
                        companyGuid={this.props.companyGuid}
                        reloadUsers={() => this.loadUsers()}
                        close={() => this.setState({showInviteUserModal: false})}/>

                    <CompanyUserCreationModal
                        open={this.state.showCreateUserModal}
                        companyGuid={this.props.companyGuid}
                        close={(reload) => {
                            this.setState({showCreateUserModal: false});

                            if (reload) {
                                this.loadUsers();
                            }
                        }}
                        companyName={this.props.company.name}/>

                    <CompanyUserPermissionsModal
                        open={this.state.showUserModal}
                        close={(reload) => {
                            this.setState({selectedUser: null, showUserModal: false});

                            if (reload) {
                                this.loadUsers();
                            }
                        }}
                        companyGuid={this.props.companyGuid}
                        selectedUser={this.state.selectedUser}/>
            </AccessGate>
    );
    }
    }

    const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadCompanyUsers, deleteCompanyUser })(CompanyUsers);