import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, Descriptions, Modal, Row, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadTransactions} from "../../actions/billing";
import {Link} from "react-router-dom";
import {EyeOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {MoneyField} from "../shared/MoneyField";
import AccessGate from "../permissions/AccessGate";

class TransactionsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        transactions: [],
        loadingTransactions: true,
        showTransactionModal: false,
        activeTransaction: {}
    }

    componentDidMount() {
        this.props.updatePage('Transactions');
        this.loadTransactions();
    }

    loadTransactions(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingTransactions: true });

        this.props.loadTransactions(page, per_page, this.state.filter, (res) => {
            this.setState({ transactions: res.data.data, loadingTransactions: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingTransactions: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(pagination, filters) {
        let filter = [];

        if(filters.status !== null) {
            filter = filters.status;
        }

        this.setState({ filter: filter }, () => {
            this.loadTransactions(pagination.current, pagination.pageSize);
        });
    }

    render() {
        const columns = [
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} />},
            { title: 'Method', align: 'center', dataIndex: 'payment_method_type', render: (item) => normalizeEnum(item)},
            { title: 'Transaction ID', dataIndex: 'transaction_id'},
            { title: '', align: 'right',
                render: (item, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ showTransactionModal: true, activeTransaction: record })}>View</Button>},
        ];

        return(
            <AccessGate scopes={['can_view_transactions']}>
                <Row justify='end' style={{paddingBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadTransactions()}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.guid}
                        dataSource={this.state.transactions}
                        loading={this.state.loadingTransactions}
                        pagination={this.state.pagination}
                        onChange={(pagination, filters, sorter, extra) => this.changePage(pagination, filters, sorter, extra)}
                    />
                </div>

                    <Modal
                        centered
                        title={'Transaction'}
                        open={this.state.showTransactionModal}
                        destroyOnClose={true}
                        onCancel={() => this.setState({showTransactionModal: false, activeTransaction: {}})}
                        footer={<Button onClick={() => this.setState({
                            showTransactionModal: false,
                            activeTransaction: {}
                        })}>Close</Button>}>

                        <Descriptions column={1} size='small' bordered>
                            <Descriptions.Item label='ID:'>{this.state.activeTransaction.guid}</Descriptions.Item>
                            <Descriptions.Item label='Created:'><DateTimeFormat
                                value={this.state.activeTransaction.date_created}/></Descriptions.Item>
                            <Descriptions.Item label='Amount:'><MoneyField
                                amount={parseFloat(this.state.activeTransaction.amount)}
                                currency={this.state.activeTransaction.currency}/> {this.state.activeTransaction.currency}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label='Method:'>{normalizeEnum(this.state.activeTransaction.payment_method_type)}</Descriptions.Item>
                            <Descriptions.Item
                                label='Transaction ID:'>{this.state.activeTransaction.transaction_id}</Descriptions.Item>
                        </Descriptions>
                        <br/>
                        <div style={{overflowX: 'auto'}}>
                            <Table
                                rowKey={(item) => item.guid}
                                pagination={false}
                                size='small'
                                dataSource={this.state.activeTransaction.associated_invoices}
                                columns={[
                                    {
                                        title: 'Invoice ID',
                                        dataIndex: 'id',
                                        render: (item) => <Link to={'/billing/invoices/' + item}>#{item}</Link>
                                    },
                                    {title: 'Status', dataIndex: 'status', render: (item) => normalizeEnum(item)}
                                ]}
                            />
                        </div>

                    </Modal>
            </AccessGate>
    )
    }
    }

    export default connect(null, { updatePage, loadTransactions })(TransactionsTable);