import { combineReducers } from 'redux';
import auth from './auth';
import search from './search';
import page from './page';
import app from './app';
import ip_addresses from './ip_addresses';
import getting_started_wizard from './getting_started_wizard';
import driver from "./driver";

export default combineReducers({
    auth, search, page, ip_addresses, app, getting_started_wizard, driver
});