import React from "react";
import { connect } from 'react-redux';
import {sidebarMenu} from "../menu";
import {Link, Switch} from "react-router-dom";
import {
    LockOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from "@ant-design/icons";
import PrivateRoute from "./shared/PrivateRoute";
import GlobalSearch from "./global_search";
import {Loading} from "../libs/loading";
import {Helmet} from "react-helmet";
import {getRootPath} from "../libs/utils";
import {Button, Col, ConfigProvider, Dropdown, Layout, Menu, message, Row, Tooltip} from "antd";
import qs from "qs";
import {getErrorMessage} from "../actions/system";
import Notifications from "./shared/Notifications";
import Announcements from "./shared/Announcements";
import Issues from "./shared/Issues";
import OverdueNotice from "./shared/OverdueNotice";
import AccountManagerProfile from "./account/AccountManagerProfile";
import {hasPermission} from "./permissions/PermissionUtils";
import {FaCrown} from "react-icons/fa";
import {IoBusinessOutline} from "react-icons/io5";
import CustomSupportProfile from "./account/CustomSupportProfile";
import {toggleSideMenu} from "../actions/app";
import {PageView} from "./PageView";
import SuspendedHostingAccountNotice from "./shared/SuspendedHostingAccountsNotice";
import GettingStartedWizard from "./shared/GettingStartedWizard";
import GettingStartedWizardFloater from "./shared/GettingStartedWizardFloater";
import {startGettingStartedWizard} from "../actions/getting_started_wizard";
import {FaWandMagicSparkles} from "react-icons/fa6";
import {MobileWarning} from "./shared/MobileWarning";


class App extends React.Component {
    state = {
        sideMenuCollapsed: false,
        activeMenuItem: '/',
        activeSubmenuItem: null,
        submenuOpen: false,
        darkMode: false,
    };

    componentDidMount() {
        this.updatePage(this.props.location.pathname);

        this.props.history.listen((location) => {
            this.updatePage(location.pathname);
        });

        this.setState({ activeMenuItem: '/' + this.props.location.pathname.split('/')[1]})

        if(this.props.location.search !== '') {
            let parsed = qs.parse(this.props.location.search.substring(1));

            if(typeof parsed.error !== 'undefined') {
                this.props.getErrorMessage(parsed.error, (res) => {
                    message.error(res.data.description, 5000);
                }, () => {});
            }
        }
    }

    updatePage(location) {
        if(typeof location !== 'undefined' && location !== '/auth/logout' && location !== '/auth/login') {
            const rootPath = getRootPath(location);
            this.setState({ activeMenuItem : rootPath, activeSubmenuItem: (rootPath === location ? rootPath : location) });
        }
    }

    render() {
        if(this.props.user === null) {
            return <Loading />;
        }

        const { user } = this.props.user;
        let routes = [];
        const newMenuItems = [];

        Object.keys(sidebarMenu).forEach(path => {
            const item = sidebarMenu[path];
            let allowCustomer = typeof item.allow_customer !== 'undefined' ? item.allow_customer : true;

            if(hasPermission(user.company.permissions, item.scopes)) {
                let showItem = false;

                if(!user.is_customer || allowCustomer) {
                    showItem = true;
                }

                if(showItem) {
                    routes.push(<PrivateRoute key={path} path={path} component={item.page} exact={item.exact} />);

                    if(!item.subpage) {
                        newMenuItems.push({
                            key: path,
                            label: item.name,
                            icon: item.icon,
                        })
                    }
                }
            }
        });

        const userMenuItems = [];

        if(!user.is_customer && hasPermission(user.company.permissions, ['can_switch_companies'])) {
            for(let i = 0; i < user.company_list.length; i++) {
                userMenuItems.push({ label: <Tooltip title='Switch to company' placement='left'>
                        <Link className='user-menu-item' to={'/account/select-company/' + user.company_list[i].guid}>
                            <IoBusinessOutline /> {user.company_list[i].name}
                        </Link>
                </Tooltip>, key: 'switch-company-' + i});
            }

            userMenuItems.push({ type: 'divider' });
        }

        userMenuItems.push({ label:  <Link
                className='user-menu-item' to='#'
                onClick={() => this.props.startGettingStartedWizard(this.props.user.user.company.guid)}>
                <FaWandMagicSparkles style={{marginBottom: '-2px'}} /> Getting Started Wizard</Link>, key: 'getting-started-wizard' });

        if(!user.is_customer) {
            userMenuItems.push({ label:  <Link className='user-menu-item' to='/account/profile'><UserOutlined /> Profile</Link>, key: 'user-profile' });
        }

        userMenuItems.push({ label:  <Link className='user-menu-item' to='/auth/logout'><LockOutlined /> Log Out</Link>, key: 'logout' });

        let useCustomSupport = user.is_customer && typeof user.company.support !== 'undefined';
        let customSupport = null;

        if(useCustomSupport) {
            customSupport = user.company.support;
        }

        const { Header, Sider, Content } = Layout;

        const { sideMenuCollapsed } = this.props.app;

        const userHeaderMenuItems = [<div key='user-name'>{user.first_name + ' ' + user.last_name}</div>]

        if(!user.is_customer) {
            userHeaderMenuItems.push(<div key='user-name-partner'><small>{user.company.name}{user.company.is_partner ? <span className='partnership-badge'><FaCrown /></span> : ''}</small></div>);
        }

        return(
            <ConfigProvider>
                <MobileWarning />
                <Layout className={'main-wrapper' + (this.props.app.darkMode ? ' dark' : '')}>
                    <Sider className='sidebar' trigger={null} width='230px' collapsible collapsed={sideMenuCollapsed}>
                        <div className='sidebar-content'>
                            <div className={'header-logo' + (sideMenuCollapsed ? ' collapsed' : '')}>
                                <img src={sideMenuCollapsed ? '/img/logo-icon.png' : '/img/logo-top.png'} alt='Logo' id='logo' />
                            </div>

                            <div className='sidebar-menu-area'>
                                <Menu items={newMenuItems} selectedKeys={[this.state.activeMenuItem]} onClick={({key}) => {
                                    this.setState({ activeMenuItem: key });

                                    if(key.substring(0, 4) === 'http') {
                                        window.open(key);
                                    } else {
                                        this.props.history.push(key);
                                    }
                                }} theme='light' />
                            </div>

                            <div className='sidebar-support-area'>
                                {this.props.user.user.company.is_partner && !sideMenuCollapsed ? <AccountManagerProfile /> : ''}
                                {useCustomSupport && customSupport !== null && !sideMenuCollapsed ? <CustomSupportProfile /> : ''}
                            </div>
                        </div>
                    </Sider>
                    <Layout>
                        <Header className='header'>
                            <div className='sidemenu-collapse'>
                                <Tooltip title={sideMenuCollapsed ? 'Expand sidebar menu' : 'Collapse sidebar menu'}>
                                    {React.createElement(sideMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: () => this.props.toggleSideMenu(),
                                    })}
                                </Tooltip>
                            </div>
                            <div className='header-search'>
                                <GlobalSearch />
                            </div>
                            <div className='header-actions'>
                                <Row>
                                    {!user.is_customer ? <Col><Notifications /></Col> : ''}
                                    <Col>
                                        <Dropdown menu={{items: userMenuItems}}>
                                            <Button type='text' className='header-user-info' onClick={e => e.preventDefault()}>
                                                {userHeaderMenuItems.map(item => item)}
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        </Header>
                        <Content className='main' onClick={() => this.state.sidebarMenuOpen ? this.setState( { sidebarMenuOpen: false }) : null}>
                            <div className='main-header'>

                                <h2 className='main-header-text'>{this.props.page.title}</h2>
                                <Helmet>
                                    <title>{this.props.page.title}</title>
                                </Helmet>
                            </div>
                            <div className='main-content'>
                                {!user.is_customer ? <Announcements /> : ''}
                                <Issues />
                                {!user.is_customer ? <OverdueNotice /> : ''}
                                {!user.is_customer ? <SuspendedHostingAccountNotice /> : ''}
                                <div className='body-container'>
                                    <PageView />
                                    <Switch>
                                        {routes}
                                    </Switch>
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                {this.props.wizard ? <GettingStartedWizard history={this.props.history} /> : ''}
                {this.props.wizard && this.props.wizard.isMinimized ? <GettingStartedWizardFloater /> : ''}
            </ConfigProvider>
        );
    }
}

const mapStateToProps = state => ({
    wizard: state.getting_started_wizard,
    user: state.auth.user,
    page: state.page,
    app: state.app
});

export default connect(mapStateToProps, { getErrorMessage, toggleSideMenu, startGettingStartedWizard })(App);