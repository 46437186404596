import React from "react";
import { connect } from 'react-redux';
import {Loading} from "../libs/loading";
import {updatePage} from "../actions/page";
import {Card, Col, Collapse, Descriptions, List, Row, Statistic} from "antd";
import {loadDashboard} from "../actions/dashboard";
import {displayErrors} from "../libs/utils";
import {DateTimeFromNow} from "../components/shared/DateTimeFromNow";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import AccessItem from "../components/permissions/AccessItem";

class Dashboard extends React.Component {
    state = {
        loadingDashboard: false,
        data: null,
        faq: [
            {
                question: 'How to get started with Priority Prospect?',
                answer: <span><a target='_blank' rel='noreferrer noopener'  href={'https://support.priorityprospect.com/knowledgebase/article/getting-started-with-priority-prospect'}><b><u>Click here to view our getting started guide</u></b></a>. It will help you get started.</span>
            },
            {
                question: 'Can I mix shared IP addresses with dedicated IP addresses?',
                answer: 'Yes, you are welcome to use our shared and dedicated IP addresses in one IP group.'
            },
            {
                question: 'How many IP addresses can I have in one IP group?',
                answer: 'You can have as many IP addresses as you wish.'
            },
            {
                question: 'How many domain groups can I have?',
                answer: 'There is no limit how many domain groups you can have.'
            },
            {
                question: 'Can I use my own web server?',
                answer: 'Absolutely! Just add a custom server from the hosting menu.'
            }
        ]
    };

    componentDidMount() {
        const { user } = this.props.user;

        this.props.updatePage('Welcome, ' + user.first_name + '!');

        this.setState({ loadingDashboard: true });

        this.props.loadDashboard((res) => {
            this.setState({ loadingDashboard: false, data: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingDashboard: false });
            }
        });
    }

    render() {
        if(this.props.user === null || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { Panel } = Collapse;

        return(
            <Row gutter={[16, 16]}>
                <Col md={18} xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <AccessItem scopes={['can_view_billing']}>
                                <Card title='Account Overview' size='small'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={6}>
                                            <Statistic title='Unpaid Invoices' value={this.state.data.unpaid_invoices} />
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Statistic title='Overdue Invoices'
                                                       suffix={this.state.data.overdue_invoices > 0 ? <ExclamationCircleOutlined style={{color: 'red'}} /> : null}
                                                       value={this.state.data.overdue_invoices} />
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Statistic title='Suspended Orders'
                                                       suffix={this.state.data.suspended_orders > 0 ? <ExclamationCircleOutlined style={{color: 'red'}} /> : null}
                                                       value={this.state.data.suspended_orders} />
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Statistic title='Active Orders' value={this.state.data.active_orders} />
                                        </Col>
                                    </Row>
                                </Card>
                                <br />
                            </AccessItem>
                            {!this.props.user.user.is_customer ? <Card title='Latest News' size='small'>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.data.news}
                                    size='small'
                                    renderItem={item => {
                                        return <List.Item actions={[<small><DateTimeFromNow value={item.date_modified} /></small>]}>
                                            <List.Item.Meta
                                                title={<a href={item.link} target='_blank' rel='noopener noreferrer'>{item.title}</a>}
                                                description={item.content.replace('\n', ' ').replace(/\s+/g, ' ')}
                                            />

                                        </List.Item>;
                                    }}
                                />
                            </Card> : <Card size='small'>
                                <p>Welcome to our management panel!</p>
                                <p>Here you can view and manage your domains and IP addresses.</p>
                            </Card>}
                        </Col>
                    </Row>
                </Col>
                <Col md={6} xs={24}>
                    <Card title='Account Usage' size='small'>
                        <Descriptions bordered size='small' column={1}>
                            <Descriptions.Item style={{width: '50%'}} label='Domains:'>{this.state.data.domain_count}/{this.state.data.website_slots_available}</Descriptions.Item>
                            <Descriptions.Item label='IP addresses:'>{this.state.data.ip_address_count}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <br />
                    {!this.props.user.user.is_customer ?<Card title='Quick FAQ' size='small'>
                        <Collapse ghost>
                            {this.state.faq.map((item, i) => {
                                return <Panel header={item.question} key={i}>
                                    <p>{item.answer}</p>
                                </Panel>;
                            })}
                        </Collapse></Card> : ''}

                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { updatePage, loadDashboard })(Dashboard);