import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Badge,
    Button,
    Col,
    ConfigProvider,
    message,
    Modal,
    Popconfirm, Row,
    Space,
    Switch,
    Table,
    Tooltip
} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DEFAULT_PER_PAGE} from "../../config";
import NewFirewallPolicyModal from "./NewFirewallPolicyModal";
import {bulkDeleteFirewallPolicies, deleteFirewallPolicy, loadFirewallPolicies, updateFirewallPolicyStatus} from "../../actions/firewall";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, FolderOpenOutlined} from "@ant-design/icons";
import EditFirewallPolicyModal from "./EditFirewallPolicyModal";
import AccessGate from "../permissions/AccessGate";
import {hasPermission} from "../permissions/PermissionUtils";
import AccessItem from "../permissions/AccessItem";

class FirewallPolicies extends React.Component {
    state = {
        policies: [],
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        showNewPolicyModal: false,
        activePolicy: '',
        showEditPolicyModal: false,
        selectedPolicies: []
    };

    componentDidMount() {
        this.props.updatePage('Firewall Policies');
        this.loadPolicies();
    }

    loadPolicies(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadFirewallPolicies(page, per_page, (res) => {
            this.setState({ policies: res.data.data, loading: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    changePage(data) {
        this.loadPolicies(data.current, data.pageSize);
    }

    toggleFirewallPolicyStatus(guid, status) {
        let data = {
            status: status ? 'ACTIVE' : 'DISABLED'
        };

        let policies = this.state.policies.map((item) => {
            if(item.guid === guid) {
                return {...item, status: data['status']};
            }

            return item;
        });

        this.setState({ policies: policies, loading: true });

        this.props.updateFirewallPolicyStatus(guid, data, () => {
            message.success('Firewall policy successfully updated!');
            this.setState({ loading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteFirewallPolicy(guid) {
        this.setState({ loading: true });

        this.props.deleteFirewallPolicy(guid, () => {
            message.success('Firewall policy successfully deleted!');
            this.loadPolicies();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    confirmBulkDelete() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure you want to delete these policies?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'This can have significant impact on your domains and content!',
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    policies: this.state.selectedPolicies
                };

                this.props.bulkDeleteFirewallPolicies(data, () => {
                    message.success('Firewall policies successfully deleted!');
                    this.loadPolicies();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        const columns = [
            { title: '', width: '50px', render: (item, record) => {
                return <Tooltip title={record.status === 'ACTIVE' ? 'Disable policy' : 'Enable policy'}>
                    <Switch size='small' disabled={!hasPermission(this.props.company.permissions, ['can_edit_firewall_policies'])}
                            checked={record.status === 'ACTIVE'} onChange={(e) => this.toggleFirewallPolicyStatus(record.guid, e)} />
                </Tooltip>
            }},
            { title: 'Name', dataIndex: 'name', render: (item) => <strong>{item}</strong> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <Badge status={item === 'ACTIVE' ? 'success' : 'error'} text={normalizeEnum(item)} /> },
            { title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ activePolicy: record.guid, showEditPolicyModal: true })}>View</Button>
                        <AccessItem scopes={['can_delete_firewall_policies']}>
                            <Popconfirm
                                title="Are you sure you want to delete this policy?"
                                onConfirm={() => this.deleteFirewallPolicy(record.guid)}
                                okText='Delete'
                                placement='topRight'
                                okButtonProps={{danger: true}}
                                cancelText='Cancel'>
                                <Tooltip title='Delete firewall policy'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>;
                }}
        ];

        return(
            <AccessGate scopes={['can_view_firewall_policies']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col span={22}>
                        <Space>
                            <AccessItem scopes={['can_add_firewall_policies']}>
                                <Button type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showNewPolicyModal: true })}>Add Firewall Policy</Button>
                            </AccessItem>
                            <AccessItem scopes={['can_delete_firewall_policies']}>
                                <Button icon={<DeleteOutlined />} disabled={this.state.selectedPolicies.length === 0} danger onClick={() => this.confirmBulkDelete()}>Delete</Button>
                            </AccessItem>
                        </Space>
                    </Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadPolicies()}/></Tooltip></Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                    <FolderOpenOutlined style={{fontSize: 40}}/>
                    <p>Nothing to see here...</p>
                    <AccessItem scopes={['can_add_firewall_policies']}>
                        <Button type='primary' icon={<PlusOutlined/>}
                                onClick={() => this.setState({showNewPolicyModal: true})}>Add Firewall Policy</Button>
                    </AccessItem>
                </div>}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.policies}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (policies) => this.setState({selectedPolicies: policies})
                            }}
                            columns={columns}
                            rowKey={(item) => item.guid}
                            pagination={this.state.pagination}
                            onChange={(e) => this.changePage(e)}
                            loading={this.state.loading}/>
                    </div>

                </ConfigProvider>

                <NewFirewallPolicyModal
                    showModal={this.state.showNewPolicyModal}
                    reloadPolicies={() => this.loadPolicies()}
                    close={() => this.setState({ showNewPolicyModal: false })} />

                <EditFirewallPolicyModal
                    showModal={this.state.showEditPolicyModal}
                    reloadPolicies={() => this.loadPolicies()}
                    policy={this.state.activePolicy}
                    close={() => this.setState({ showEditPolicyModal: false })} />
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadFirewallPolicies, updateFirewallPolicyStatus, deleteFirewallPolicy, bulkDeleteFirewallPolicies })(FirewallPolicies);