import {Alert, Col, Grid, Row} from 'antd';
const { useBreakpoint } = Grid;

export const MobileWarning = () => {
    const s = useBreakpoint();

    if(!s.lg && !s.md && !s.xl && !s.xxl && (s.xs || s.sm)) {
        return <Row>
            <Col>
                <Alert type='warning' showIcon message={<div><b>Attention!</b> For the best experience, please use a device with a larger screen, such as a tablet, laptop, or desktop, as some actions may be optimized for these devices.</div>} />
            </Col>
        </Row>
    }

    return <></>
}