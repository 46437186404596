import React from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Row, Table, Tooltip} from "antd";
import {loadCompanyLogs} from "../../actions/account";
import {updatePage} from "../../actions/page";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import AccessGate from "../permissions/AccessGate";

class CompanyLog extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        logs: [],
        loadingLogs: true
    };

    componentDidMount() {
        this.loadLogs();
    }

    loadLogs(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingLogs: true });

        this.props.loadCompanyLogs(this.props.companyGuid, page, per_page, null, (res) => {
            this.setState({ logs: res.data.data, loadingLogs: false, pagination:
                    { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingLogs: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadLogs(data.current, data.pageSize);
    }

    parseLog(row) {
        if(row.action_object !== null) {
            let object_name = row.action_object.name;

            if(typeof row.action_object.id !== 'undefined') {
                object_name = row.action_object.id;
            }

            if(row.action_object_type === 'i_p_address_order') {
                row.action_object_type = 'ip_address_order';
            }

            return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb + ' "' + object_name + '" (' + normalizeEnum(row.action_object_type) + ')'}</span>;
        }

        if(row.target !== null) {
            let target = row.target.name;

            if(typeof row.target.id !== 'undefined') {
                target = row.target.id + ' (' + normalizeEnum(row.target_type) + ')';
            }

            return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb + ' "' + target + '"'}</span>;
        }

        return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb}</span>;
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'date_created', key: 'timestamp', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'IP Address', dataIndex: 'ip_address', key: 'ip_address' },
            { title: 'Useragent', dataIndex: 'user_agent', key: 'user_agent' },
            { title: 'Action', key: 'action', width: '40%', render: (item, row) => this.parseLog(row)}
        ];

        return(
            <AccessGate scopes={['can_view_company_logs']}>
                <Row justify='end' style={{marginBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadLogs()}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.guid}
                        dataSource={this.state.logs}
                        loading={this.state.loadingLogs}
                        pagination={this.state.pagination}
                        onChange={(e) => this.changePage(e)}
                    />
                </div>
            </AccessGate>
    );
    }
    }

    export default connect(null, { updatePage, loadCompanyLogs })(CompanyLog);