import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Link} from "react-router-dom";
import {DEFAULT_DOMAINS_PER_PAGE} from "../../config";
import {Card, Col, ConfigProvider, message, Popconfirm, Row, Table, Tooltip} from "antd";
import Button from "antd/es/button";
import {createDomainGroup, deleteDomainGroup, loadDomainGroups} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";
import {DateFormat} from "../../components/shared/DateFormat";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import AddDomainGroupModal from "../../components/domain/AddDomainGroupModal";
import {DeleteOutlined, FolderOpenOutlined} from "@ant-design/icons";
import AccessGate from "../../components/permissions/AccessGate";
import AccessItem from "../../components/permissions/AccessItem";
import {hasPermission} from "../../components/permissions/PermissionUtils";
import {disableDriver} from "../../actions/driver";

class Domains extends React.Component {
    state = {
        filters: null,
        groupsLoaded: false,
        groups: [],
        showAddModal: false,
        newGroupName: '',
        creatingNewGroup: false,
        pagination: { current: 1, pageSize: DEFAULT_DOMAINS_PER_PAGE, showSizeChanger: true, total: 0 }
    };

    componentDidMount() {
        this.props.updatePage('Domain Groups');
        this.loadGroups();
    }

    loadGroups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ groupsLoaded: false });

        this.props.loadDomainGroups(page, per_page, this.state.filters, (response) => {
            this.setState({ groups: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, showSizeChanger: true, total: response.data.total }, groupsLoaded: true });
        }, (err) => {
            this.setState({ groupsLoaded: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteGroup(guid) {
        this.setState({ groupsLoaded: false });

        this.props.deleteDomainGroup(guid, () => {
            message.success('Domain group successfully deleted!');
            this.loadGroups();
        }, (err) => {
            this.setState({ groupsLoaded: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    showAddDomainGroupModal() {
        this.setState({ showAddModal: true });
        
        this.props.disableDriver();
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (item, record) => <strong><Link to={'/domains/' + record.guid}>{record.name}</Link></strong>},
            { title: 'Domains', dataIndex: 'domain_count', align: 'center', key: 'domain_count'},
            { title: 'Created', dataIndex: 'date_created', align: 'center', key: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
        ];

        if(hasPermission(this.props.company.permissions, ['can_delete_ip_groups'])) {
            columns.push({ title: '', align: 'right', render: (item, record) => (
                    <Popconfirm
                        title="Are you sure you want to delete this group?"
                        onConfirm={() => this.deleteGroup(record.guid)}
                        okText='Delete'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        disabled={record.domain_count > 0}
                        cancelText='Cancel'>
                        <Tooltip title='Delete domain group' placement='left'>
                            <Button icon={<DeleteOutlined />} danger size='small' disabled={record.domain_count > 0} />
                        </Tooltip>
                    </Popconfirm>
                )});
        }

        return(
            <AccessGate scopes={['can_view_domain_groups']}>
                <Card size='small'>
                    <Row justify='space-between' style={{paddingBottom: '10px'}}>
                        <Col>
                            <AccessItem scopes={['can_add_domain_groups']}>
                                <Button type='primary' className='add-domain-group-btn' icon={<PlusOutlined />} onClick={() => this.showAddDomainGroupModal()}>New Domain Group</Button>
                            </AccessItem>
                        </Col>
                        <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadGroups()}/></Tooltip></Col>
                    </Row>

                    <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                        <FolderOpenOutlined style={{fontSize: 40}}/>
                        <p>Nothing to see here...</p>
                        <AccessItem scopes={['can_add_domain_groups']}>
                            <Button icon={<PlusOutlined/>} onClick={() => this.setState({showAddModal: true})}>Add
                                Domain Group</Button>
                        </AccessItem>
                    </div>}>
                        <div style={{overflowX: 'auto'}}>
                            <Table
                                dataSource={this.state.groups}
                                columns={columns}
                                rowKey={(item) => item.guid}
                                pagination={this.state.pagination}
                                onChange={(pagination) => this.loadGroups(pagination.current, pagination.pageSize)}
                                loading={!this.state.groupsLoaded}/>
                        </div>
                    </ConfigProvider>
                </Card>

                <AddDomainGroupModal
                    reloadGroups={() => this.loadGroups()}
                    showModal={this.state.showAddModal}
                    history={this.props.history}
                    close={() => this.setState({ showAddModal: false })} />
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadDomainGroups, createDomainGroup,
    deleteDomainGroup, disableDriver })(Domains);