import React, {Fragment} from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Row, Table, Tooltip} from "antd";
import {loadLogs} from "../../actions/account";
import {updatePage} from "../../actions/page";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class Logs extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        logs: [],
        loadingLogs: true
    };

    componentDidMount() {
        this.props.updatePage('Logs');
        this.loadLogs();
    }

    loadLogs(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingLogs: true });

        this.props.loadLogs(page, per_page, null, (res) => {
            this.setState({ logs: res.data.data, loadingLogs: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingLogs: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadLogs(data.current, data.pageSize);
    }

    parseLog(row) {
        if(row.action_object !== null) {
            return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb + ' "' + row.action_object.name + '".'}</span>;
        }

        if(row.target !== null) {
            return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb + ' "' + row.target.name + '".'}</span>;
        }

        return <span>{row.actor.first_name + ' ' + row.actor.last_name + ' ' + row.verb + '.'}</span>;
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Useragent', dataIndex: 'user_agent' },
            { title: 'Action', key: 'action', width: '40%', render: (item, row) => this.parseLog(row)}
        ];

        return(
            <Fragment>
                <Row justify='end'>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadLogs()}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.guid}
                        dataSource={this.state.logs}
                        loading={this.state.loadingLogs}
                        pagination={this.state.pagination}
                        onChange={(e) => this.changePage(e)}
                    />
                </div>
            </Fragment>
    );
    }
    }

    export default connect(null, { updatePage, loadLogs })(Logs);