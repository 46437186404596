import React, {Fragment} from 'react';
import {Button, Descriptions, Modal, Space, Table} from "antd";
import {connect} from "react-redux";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadCancellationRequest} from "../../actions/billing";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";

class CancellationRequestDetailModal extends React.Component {
    state = {
        cancellationRequest: '',
        loadingCancellationRequest: false,
        cancellationRequestLoaded: false,
        data: null
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if(!this.state.cancellationRequestLoaded && this.props.showModal && !this.state.loadingCancellationRequest)
        {
            this.setState({ loadingCancellationRequest: true}, () =>
            {
                this.props.loadCancellationRequest(prevState.cancellationRequest, (res) =>
                {
                    this.setState({...this.state, data: res.data, cancellationRequestLoaded: true, loadingCancellationRequest: false});
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ cancellationRequestLoaded: true, loadingCancellationRequest: false });
                        displayErrors(err.response.data);
                    }
                });
            });
        }
    }

    closeModal() {
        this.setState({ loadingCancellationRequest: false, cancellationRequestLoaded: false });
        this.props.close();
    }

    render() {
        let content = <div className='text-center'><Loading/></div>;

        if(this.state.data !== null) {
            const { data } = this.state;
            const columns = [
                { title: 'IP address', dataIndex: 'ip_address' },
                { title: 'Type', dataIndex: 'ip_address_type', render: (item) => normalizeEnum(item) },
                { title: 'Location', render: (item, record) => <span>{record.city + ', ' + record.country}</span> },
                { title: 'Group', render: (item, record) => <Link to={'/ip-addresses/' + record.group.guid}>{record.group.name}</Link> },
            ];

            content = <Fragment>
                <Space direction='vertical' style={{width: '100%'}}>
                    <Descriptions bordered size='small' layout='horizontal' column={1} style={{width: '100%'}}>
                        <Descriptions.Item label='Requested'><DateTimeFormat value={data.date_created} /></Descriptions.Item>
                        <Descriptions.Item label='Processed'><DateTimeFormat value={data.date_processed} /></Descriptions.Item>
                        <Descriptions.Item label='Status'><StatusIndicator el='badge' status={data.status} /></Descriptions.Item>
                        <Descriptions.Item label='Cancellation time'>{normalizeEnum(data.cancellation_time)}</Descriptions.Item>
                        {data.order !== null ? <Descriptions.Item label='Order'><Link to={'/billing/orders/' + data.order}>#{data.order}</Link></Descriptions.Item> : ''}
                    </Descriptions>
                    {data.ip_addresses.length > 0 ? <div style={{overflowX: 'auto'}}><Table style={{width: '100%'}}
                                                                                            size='small'
                                                                                            dataSource={data.ip_addresses}
                                                                                            columns={columns}
                                                                                            rowKey={(item) => item.guid}/> </div>:
                        ''}
                    </Space>
                        </Fragment>;
        }

        return (
            <Modal
                centered
                title='Cancellation Request'
                open={this.state.showModal}
                width='700px'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Button onClick={() => this.closeModal()}>Close</Button>}>
                {content}
            </Modal>
        )
    }
}

export default connect(null, { loadCancellationRequest })(CancellationRequestDetailModal);