import {UPDATE_DRIVER_HIGHLIGHT, DISABLE_DRIVER, UPDATE_DRIVER_MULTIPLE_HIGHLIGHTS, NEXT_STEP_DRIVER} from "./types";

export const updateDriverHighlight = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_DRIVER_HIGHLIGHT, payload });
}

export const disableDriver = (payload) => (dispatch) => {
    dispatch({ type: DISABLE_DRIVER, payload });
}

export const updateDriverMultipleHighlights = (payload) => (dispatch) => {
    dispatch({ type: UPDATE_DRIVER_MULTIPLE_HIGHLIGHTS, payload });
}
export const driverNextStep = (payload) => (dispatch) => {
    dispatch({ type: NEXT_STEP_DRIVER, payload });
}