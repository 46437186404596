import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Badge,
    Button,
    Popconfirm,
    Switch,
    Table,
    Tooltip,
    message,
    Modal,
    Space,
    ConfigProvider,
    Col, Row, Tag, Popover, Descriptions
} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {DEFAULT_PER_PAGE} from "../../config";
import {bulkDeleteFirewallRules, deleteFirewallRule, loadFirewallRules, updateFirewallRuleStatus} from "../../actions/firewall";
import {Link} from "react-router-dom";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class FirewallRules extends React.Component {

    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        loading: true,
        rules: [],
        selectedRules: []
    };

    componentDidMount() {
        this.props.updatePage('Firewall Rules');
        this.loadRules();
    }

    loadRules(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.props.loadFirewallRules(page, per_page,(res) => {
            this.setState({ rules: res.data.data, pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, loading: false });
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteFirewallRule(guid) {
        this.setState({ loading: true });

        this.props.deleteFirewallRule(guid, () => {
            message.success('Firewall rule successfully deleted!');
            this.loadRules();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    toggleFirewallRuleStatus(guid, status) {
        let data = {
            status: status ? 'ACTIVE' : 'DISABLED'
        };

        let rules = this.state.rules.map((item) => {
            if(item.guid === guid) {
                return {...item, status: data['status']};
            }

            return item;
        });

        this.setState({ rules: rules, loading: true });

        this.props.updateFirewallRuleStatus(guid, data, () => {
            message.success('Firewall rule successfully updated!');
            this.setState({ loading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadRules(data.current, data.pageSize);
    }

    confirmBulkDelete() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure you want to delete these rules?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'This can have significant impact on your domains and content!',
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    rules: this.state.selectedRules
                };

                this.props.bulkDeleteFirewallRules(data, () => {
                    message.success('Firewall rules successfully deleted!');
                    this.loadRules();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        const columns = [
            { title: '', width: '50px', render: (item, record) => {
                return <Tooltip title={record.status === 'ACTIVE' ? 'Disable rule' : 'Enable rule'}>
                    <Switch size='small' disabled={!hasPermission(this.props.company.permissions, ['can_edit_firewall_rules'])}
                            checked={record.status === 'ACTIVE'} onChange={(e) => this.toggleFirewallRuleStatus(record.guid, e)} />
                </Tooltip>
            }},
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/firewall/edit/' + record.guid}><strong>{item}</strong></Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <Badge status={item === 'ACTIVE' ? 'success' : 'error'} text={normalizeEnum(item)} /> },
            { title: 'Priority', dataIndex: 'priority', align: 'center'},
            { title: 'Attached To', dataIndex: 'attached_to_policies', render: (policies) => {
                if(policies.length === 0) {
                    return <Popover content={<span>This rule is not active because it is not<br /> attached to any firewall policies!</span>}>
                        <Tag color='red' icon={<ExclamationCircleOutlined />}>Not attached!</Tag>
                    </Popover>;
                }

                let labelText = '';

                    if(policies.length === 1) {
                        labelText = '1 policy';
                    } else if(policies.length > 1) {
                        labelText = policies.length + ' policies';
                    }

                    let popoverContent = <Descriptions bordered size='small' column={1}>
                        {policies.map((policy, index) => <Descriptions.Item key={index}>{policy.name}</Descriptions.Item>)}
                    </Descriptions>

                return  <AccessItem scopes={['can_view_firewall_policies']}>
                    <Popover content={popoverContent} title="Firewall Policies">
                        <Button size='small' type='text'>{labelText}</Button>
                    </Popover>
                </AccessItem>;
            }},
            { title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        <Link to={'/firewall/edit/' + record.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>
                        <AccessItem scopes={['can_delete_firewall_rules']}>
                            <Popconfirm
                                title="Are you sure you want to delete this rule?"
                                onConfirm={() => this.deleteFirewallRule(record.guid)}
                                okText='Delete'
                                placement='topRight'
                                okButtonProps={{danger: true, type: 'primary'}}
                                cancelText='Cancel'>
                                <Tooltip title='Delete firewall rule'>
                                    <Button size='small' danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        </AccessItem>
                    </Space>;
                }}
        ];

        return(
            <AccessGate scopes={['can_view_firewall_rules']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col span={22}>
                        <Space>
                            <AccessItem scopes={['can_add_firewall_rules']}>
                                <Link to='/firewall/add'><Button type='primary' icon={<PlusOutlined />}>Add Firewall Rule</Button></Link>
                            </AccessItem>
                            <AccessItem scopes={['can_delete_firewall_rules']}>
                                <Button disabled={this.state.selectedRules.length === 0} onClick={() => this.confirmBulkDelete()} danger icon={<DeleteOutlined />}>Delete</Button>
                            </AccessItem>
                        </Space>
                    </Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadRules()}/></Tooltip></Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                    <FolderOpenOutlined style={{fontSize: 40}}/>
                    <p>Nothing to see here...</p>
                    <AccessItem scopes={['can_add_firewall_rules']}>
                        <Button type='primary' icon={<PlusOutlined/>}
                                onClick={() => this.props.history.push('/firewall/add')}>Add Firewall Rule</Button>
                    </AccessItem>
                </div>}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.rules}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (rules) => this.setState({selectedRules: rules})
                            }}
                            columns={columns}
                            rowKey={(item) => item.guid}
                            pagination={this.state.pagination}
                            onChange={(e) => this.changePage(e)}
                            loading={this.state.loading}/>
                    </div>

                </ConfigProvider>
            </AccessGate>
    );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadFirewallRules, updateFirewallRuleStatus,
    deleteFirewallRule, bulkDeleteFirewallRules })(FirewallRules);