import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Row, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadOrders} from "../../actions/billing";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";
import {EyeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import AccessGate from "../permissions/AccessGate";

class OrdersTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        orders: [],
        loadingOrders: true
    }

    componentDidMount() {
        this.props.updatePage('Orders');
        this.loadOrders();
    }

    loadOrders(page = null, per_page = null) {
        if(per_page !== this.state.pagination.pageSize) {
            page = 1;
        }

        if(page == null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingOrders: true });

        this.props.loadOrders(page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ orders: res.data.data, loadingOrders: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingOrders: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Order ID', dataIndex: 'id', render: (item, record) => <strong><Link to={'/billing/orders/' + record.id}>#{record.id}</Link></strong> },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: 'Next Invoice', align: 'center', dataIndex: 'date_next_invoice', render: (item, record) => <DateFormat value={record.date_next_invoice} /> },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Billing Cycle', align: 'center', dataIndex: 'billing_cycle', render: item => normalizeEnum(item) },
            { title: 'Type', align: 'center', dataIndex: 'order_type', render: item => normalizeEnum(item) },
            { title: 'Tags', dataIndex: 'tags', width: '200px', render: (item) => item.length > 0 ? item.map((tag, index) => <Tag key={index}>{tag}</Tag>) : '-'},
            { title: '', align: 'right', render: (item, record) => <Link to={'/billing/orders/' + record.id}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}
        ];

        return(
            <AccessGate scopes={['can_view_orders']}>
                <Row justify='end' style={{paddingBottom: '10px'}}>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadOrders()}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.id}
                        dataSource={this.state.orders}
                        loading={this.state.loadingOrders}
                        pagination={this.state.pagination}
                        onChange={(pagination) => this.loadOrders(pagination.current, pagination.pageSize)}
                    />
                </div>
            </AccessGate>
    )
    }
    }

    export default connect(null, { updatePage, loadOrders })(OrdersTable);