import React from "react";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import {Col, ConfigProvider, Form, Input, message, Modal, Popconfirm, Row, Table, Tooltip} from "antd";
import AccessGate from "../permissions/AccessGate";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {FolderOpenOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {createIpAddressGroup, deleteIPAddressGroup, loadIPAddressGroups} from "../../actions/ip_addresses";
import {Link} from "react-router-dom";
import {DateFormat} from "../shared/DateFormat";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";
import {openGettingStartedWizard, updateGettingStartedWizardStep} from "../../actions/getting_started_wizard";
import {disableDriver} from "../../actions/driver";

class IPGroupsTable extends React.Component {
    state = {
        filters: null,
        groupsLoaded: false,
        groups: [],
        showAddModal: false,
        newGroupName: '',
        creatingNewGroup: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 }
    };

    componentDidMount() {
        this.props.updatePage('IP Address Groups');
        this.loadGroups();
    }

    loadGroups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ groupsLoaded: false });

        this.props.loadIPAddressGroups(page, per_page, this.state.filters, (response) => {
            this.setState({ groups: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, showSizeChanger: true, total: response.data.total }, groupsLoaded: true });
        }, (err) =>
        {
            this.setState({ groupsLoaded: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    createGroup() {
        if(this.state.newGroupName.trim() === '') {
            return message.error('Please enter group name!');
        }

        this.setState({ creatingNewGroup: true });

        this.props.createIpAddressGroup(this.state.newGroupName, (res) => {

            this.setState({ newGroupName: '', creatingNewGroup: false, showAddModal: false });

            message.success('IP address group successfully created!');

            if(this.props.wizard.currentStep === 5) {
                this.props.openGettingStartedWizard();
                this.props.updateGettingStartedWizardStep(this.props.company.guid, 6);

                this.props.history.push('/ip-addresses/' + res.data.guid);
            } else {
                this.loadGroups();
            }

        }, (err) => {
            this.setState({ creatingNewGroup: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteGroup(guid) {
        this.setState({ groupsLoaded: false });

        this.props.deleteIPAddressGroup(guid, () => {
            message.success('IP address group successfully deleted!');

            this.loadGroups();
        }, (err) => {
            this.setState({ groupsLoaded: true});
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    showAddIPGroupModal() {
        this.setState({ showAddModal: true });

        this.props.disableDriver();
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (value, row) => <strong><Link to={'/ip-addresses/' + row.guid}>{row.name}</Link></strong>},
            { title: 'IP Addresses', dataIndex: 'ip_address_count', align: 'center', key: 'ip_address_count'},
            { title: 'Created', dataIndex: 'date_created', key: 'date_created', align: 'center', render: (value) => <DateFormat value={value} />},
        ];

        if(hasPermission(this.props.company.permissions, ['can_delete_ip_groups'])) {
            columns.push({ title: '', dataIndex: 'actions', align: 'right', render: (item, row) => (
                    <Popconfirm
                        title="Are you sure delete this group?"
                        onConfirm={() => this.deleteGroup(row.guid)}
                        okText='Delete'
                        disabled={row.ip_address_count > 0}
                        okButtonProps={{danger: true}}
                        placement='topRight'
                        cancelText='Cancel'>
                        {row.ip_address_count > 0 ? <Tooltip title='IP address group is not empty!'><Button icon={<DeleteOutlined />} danger size='small' disabled={true} /></Tooltip> : <Button  icon={<DeleteOutlined />} danger size='small'>Delete</Button>}
                    </Popconfirm>
                )});
        }

        return(
            <AccessGate scopes={['can_view_ip_groups']}>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_ip_groups']}>
                            <Button type='primary' className='add-ip-group-btn' icon={<PlusOutlined />} onClick={() => this.showAddIPGroupModal()}>New IP Address Group</Button>
                        </AccessItem>
                    </Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadGroups()}/></Tooltip></Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                    <FolderOpenOutlined style={{fontSize: 40}}/>
                    <p>Nothing to see here...</p>
                    <AccessItem scopes={['can_add_ip_groups']}>
                        <Button icon={<PlusOutlined/>} onClick={() => this.setState({showAddModal: true})}>Add IP
                            Address Group</Button>
                    </AccessItem>
                </div>}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.groups}
                            columns={columns}
                            rowKey={(item) => item.guid}
                            pagination={this.state.pagination}
                            onChange={(pagination) => this.loadGroups(pagination.current, pagination.pageSize)}
                            loading={!this.state.groupsLoaded}/>
                    </div>

                </ConfigProvider>

                <Modal
                    title="Add IP Address Group"
                    open={this.state.showAddModal}
                    onCancel={() => this.setState({ showAddModal: false, newGroupName: '' })}
                    okText='Add IP Address Group'
                    onOk={() => this.createGroup()} confirmLoading={this.state.creatingNewGroup}>
                    <Form layout='vertical'>
                        <Form.Item label='Name:'>
                            <Input autoFocus onChange={(e) => this.setState({ newGroupName: e.target.value })} />
                        </Form.Item>
                    </Form>
                </Modal>
            </AccessGate>
        );
    }
}

const mapStateToProps = state => ({
    company: state.auth.user.user.company,
    wizard: state.getting_started_wizard
});

export default connect(mapStateToProps, { updatePage, loadIPAddressGroups, createIpAddressGroup,
    deleteIPAddressGroup, updateGettingStartedWizardStep, openGettingStartedWizard, disableDriver })(IPGroupsTable);