import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {acceptUserInvitation, loadInvitationCode, loadUserInvitations, revokeUserInvitation} from "../../actions/account";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import {Button, Col, message, Modal, Popconfirm, Row, Table, Tooltip, Typography} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {CheckOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {Loading} from "../../libs/loading";
import {loadUser} from "../../actions/auth";
import {StatusIndicator} from "../shared/Status";

class Invitations extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        invitations: [],
        loadingInvitations: false,
        showInvitationCodeModal: false,
        loadingInvitationCode: false,
        invitationCode: ''
    };

    componentDidMount() {
        this.props.updatePage('Invitations');
        this.loadUserInvitations();
    }

    loadUserInvitations(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingInvitations: true });

        this.props.loadUserInvitations(page, per_page,(res) => {
            this.setState({ loadingInvitations: false, invitations: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingInvitations: false });
            }
        });
    }

    loadInvitationCode() {
        this.setState({ loadingInvitationCode: true, showInvitationCodeModal: true });

        this.props.loadInvitationCode((res) => {
            this.setState({ loadingInvitationCode: false, invitationCode: res.data.invitation_code });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingInvitationCode: false, showInvitationCodeModal: false });
            }
        });
    }

    revokeUserInvitation(guid) {
        this.setState({ loadingInvitations: true });

        this.props.revokeUserInvitation(guid, () => {
            this.setState({ loadingInvitations: false });
            message.success('Invitation successfully revoked!')
            this.props.loadUser();
            this.loadUserInvitations();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingInvitations: false });
            }
        });
    }

    acceptInvitation(guid) {
        this.setState({ loadingInvitations: true });

        this.props.acceptUserInvitation(guid, () => {
            this.setState({ loadingInvitations: false });
            this.props.loadUser();
            message.success('Invitation successfully accepted!')

            this.loadUserInvitations();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingInvitations: false });
            }
        });
    }

    render() {
        const columns = [
            { title: 'Company', dataIndex: 'company' },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { align: 'right', render: (item, record) => {
                return record.status === 'PENDING' ? <Popconfirm
                        title="Are you sure you want to accept this invitation?"
                        onConfirm={() => this.acceptInvitation(record.guid)}
                        okText='Accept'
                        placement='topRight'
                        cancelText='Cancel'>
                        <Button size='small' type='primary' icon={<CheckOutlined />}>Accept</Button>
                    </Popconfirm> : '';
            }}
        ];

        const { Paragraph } = Typography;

        return(
            <Fragment>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col><Button type='primary' icon={<UserSwitchOutlined/>} onClick={() => this.loadInvitationCode()}>Get
                        Invitation Code</Button></Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadUserInvitations()}/></Tooltip></Col>
                </Row>

                <div style={{overflowX: 'auto'}}>
                    <Table
                        onChange={(pagination) => this.loadUserInvitations(pagination.current, pagination.pageSize)}
                        dataSource={this.state.invitations}
                        columns={columns}
                        rowKey={(item) => item.guid}
                        pagination={this.state.pagination}
                        loading={this.state.loadingInvitations}/>
                </div>

                    <Modal
                        title='Invitation Code'
                        footer={
                            <Button onClick={() => this.setState({showInvitationCodeModal: false})}>Close</Button>
                        }
                        onCancel={() => this.setState({showInvitationCodeModal: false})}
                        open={this.state.showInvitationCodeModal}>
                        {this.state.loadingInvitationCode ? <div className='text-center'><Loading/></div> : <Fragment>
                            <p>Your invitation code is shown below:</p>
                            <Paragraph code copyable>{this.state.invitationCode}</Paragraph>
                            <p>Your invitation code is dynamic. Make sure you check it every time you wish to share it
                                with someone.</p>
                        </Fragment>}
                    </Modal>

            </Fragment>
    );
    }
    }

    export default connect(null, { updatePage, loadUserInvitations, loadInvitationCode, revokeUserInvitation, acceptUserInvitation, loadUser })(Invitations);