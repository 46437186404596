import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Alert,
    Card,
    Col,
    ConfigProvider, Divider,
    Form,
    Input, message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Tooltip
} from "antd";
import {
    downloadAffiliateInvoice,
    loadAffiliateBillingInfo,
    loadAffiliateBillingInvoices, loadAffiliatePaymentDetails, updateAffiliatePaymentDetails
} from "../../actions/affiliate";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import Button from "antd/es/button";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DateFormat} from "../shared/DateFormat";
import {MoneyField} from "../shared/MoneyField";
import {Loading} from "../../libs/loading";
import {Link} from "react-router-dom";
import {BankOutlined, EyeOutlined, FilePdfOutlined} from "@ant-design/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import countryList from "react-select-country-list";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class AffiliateBilling extends React.Component {
    state = {
        loading: false,
        data: null,
        loadingInvoices: false,
        invoices: [],
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        showPaymentDetailsModal: false,
        loadingPaymentDetails: false,
        paymentDetails: [],

        paymentCurrency: 'USD',
        paymentMethod: 'PAYPAL',

        paypalEmail: '',

        accountName: '',
        billingEmail: '',
        ibanAccountNo: '',
        swiftBic: '',

        achRoutingNo: '',
        achAccountNo: '',
        achAccountType: '',

        recipientCountry: '',
        recipientCity: '',
        recipientAddress: '',
        recipientPostCode: '',

        bankName: '',
        bankCountry: '',
        bankCity: '',
        bankAddress: '',
        bankPostCode: '',
    }

    countries = countryList().getData();
    componentDidMount() {
        this.props.updatePage('Billing');

        this.loadPaymentDetails();
        this.loadBillingInfo();
        this.loadInvoices();
    }

    loadBillingInfo() {
        this.setState({ loading: true });

        this.props.loadAffiliateBillingInfo((res) => {
            this.setState({ loading: false, data: res.data });
        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    loadPaymentDetails() {
        this.setState({ loadingPaymentDetails: true });

        this.props.loadAffiliatePaymentDetails((res) => {
            let paymentDetails = res.data;

            if(paymentDetails !== null && paymentDetails !== '') {
                this.setState({ loadingPaymentDetails: false, paymentDetails: [paymentDetails] });
            } else {
                this.setState({ loadingPaymentDetails: false, paymentDetails: [] });
            }

        }, (err) => {
            console.log('err', err)
            this.setState({ loadingPaymentDetails: false });
            displayErrors(err.response.data);
        });
    }

    loadInvoices(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingInvoices: true });

        this.props.loadAffiliateBillingInvoices(page, per_page, (res) => {
            this.setState({ invoices: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, loadingInvoices: false });
        }, (err) => {
            this.setState({ loadingInvoices: true});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    closePaymentDetailsModal() {
        this.setState({
            showPaymentDetailsModal: false,

            paymentCurrency: 'USD',
            paymentMethod: 'PAYPAL',

            paypalEmail: '',

            accountName: '',
            billingEmail: '',
            ibanAccountNo: '',
            swiftBic: '',

            achRoutingNo: '',
            achAccountNo: '',
            achAccountType: '',

            recipientCountry: '',
            recipientCity: '',
            recipientAddress: '',
            recipientPostCode: '',

            bankName: '',
            bankCountry: '',
            bankCity: '',
            bankAddress: '',
            bankPostCode: '',
        });
    }

    updatePaymentDetails() {
        let currency = this.state.paymentCurrency;
        let payment_method = this.state.paymentMethod;
        let billing_email = this.state.billingEmail;
        let paypal_email = this.state.paypalEmail;
        let account_name = this.state.accountName;
        let iban = this.state.ibanAccountNo;
        let swift_bic = this.state.swiftBic;
        let ach_routing_no = this.state.achRoutingNo;
        let ach_account_no = this.state.achAccountNo;
        let ach_account_type = this.state.achAccountType;
        let country = this.state.recipientCountry;
        let city = this.state.recipientCity;
        let address = this.state.recipientAddress;
        let post_code = this.state.recipientPostCode;
        let bank_name = this.state.bankName;
        let bank_country = this.state.bankCountry;
        let bank_city = this.state.bankCity;
        let bank_address = this.state.bankAddress;
        let bank_post_code = this.state.bankPostCode;

        let data = { currency, payment_method };

        if(payment_method === 'PAYPAL') {
            data = {...data, paypal_email };
        } else {
            data = {...data, billing_email, country, city, address, post_code, account_name, bank_country, bank_city,
                bank_address, bank_post_code, bank_name };

            if(payment_method === 'ACH') {
                data = {...data, ach_routing_no, ach_account_no, ach_account_type };
            } else if(payment_method === 'SWIFT') {
                data = {...data, iban, swift_bic };
            }
        }

        this.setState({ loadingPaymentDetails: true });

        this.props.updateAffiliatePaymentDetails(data, () => {
            message.success('Payment method successfully updated!');

            this.closePaymentDetailsModal();
            this.loadPaymentDetails();
        }, (err) => {
            this.setState({ loadingPaymentDetails: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        console.log(this.state);

        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/affiliate/billing/invoice/' + item}>#{item}</Link> },
            { title: 'Amount', dataIndex: 'gross_amount', render: (item) => <MoneyField amount={parseFloat(item)} currency='USD' />},
            { title: 'Tax', dataIndex: 'tax', render: (item) => <MoneyField amount={parseFloat(item)} currency='USD' />},
            { title: 'Date', dataIndex: 'date_created', render: (item) => <DateFormat value={item} />},
            { title: 'Period Start', dataIndex: 'period_start', render: (item) => <DateFormat value={item} />},
            { title: 'Period End', dataIndex: 'period_end', render: (item) => <DateFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => <Space>
                    <Link to={'/affiliate/billing/invoice/' + record.id}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>
                    <Tooltip title='Download PDF'>
                        <Button size='small' type='default' icon={<FilePdfOutlined />} onClick={() => this.props.downloadAffiliateInvoice(record.id)} />
                    </Tooltip>
            </Space> }
        ];

        const paymentDetailsColumns = [
            { title: 'Created', width: '250px', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Description', dataIndex: 'description'}
        ];

        const { data } = this.state;
        const { Option } = Select;

        return <Fragment>
            <Card bordered={false} size='small' title='Payment terms'>
                <Row gutter={[16, 16]}>
                    <Col sm={24} md={4}>
                        <Statistic title="Payment threshold" value={data.payment_threshold} prefix='$' precision={2} suffix='USD' loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={5}>
                        <Statistic title="Payment terms" value={data.payment_term} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={5}>
                        <Statistic title="Billing cycle" value={normalizeEnum(data.billing_cycle)} loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={5}>
                        <Statistic title="Cookie duration" value={data.cookie_duration} suffix='days' loading={this.state.loading} />
                    </Col>
                    <Col sm={24} md={5}>
                        <Statistic title="Rate" value={data.payout_rate} precision={2} suffix='%' loading={this.state.loading} />
                    </Col>
                </Row>
            </Card>
            <br />
            <Card bordered={false} size='small' title='Invoices'>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col></Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined />} onClick={() => this.loadInvoices()}/></Tooltip></Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                    <p>Nothing to see here...</p>
                </div>}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.invoices}
                            columns={columns}
                            rowKey={(item) => item.id}
                            pagination={this.state.pagination}
                            onChange={(pagination) => this.loadInvoices(pagination.current, pagination.pageSize)}
                            loading={this.state.loading}/>
                    </div>
                </ConfigProvider>
            </Card><br/>
            <Card bordered={false} size='small' title='Payment Details'
                  extra={<Button size='small' type='primary' icon={<PlusOutlined />} onClick={() => this.setState({ showPaymentDetailsModal: true })}>{this.state.paymentDetails.length > 0 ? 'Update' : 'Add'} Payment Details</Button>}>
                <ConfigProvider renderEmpty={() => <div style={{textAlign: 'center'}}>
                    <BankOutlined style={{fontSize: 40}}/>
                    <p>Nothing to see here!</p>
                    <Button type='primary' icon={<PlusOutlined/>}
                            onClick={() => this.setState({showPaymentDetailsModal: true})}>{this.state.paymentDetails.length > 0 ? 'Update' : 'Add'} Payment
                        Details</Button>
                </div>}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.paymentDetails}
                            columns={paymentDetailsColumns}
                            rowKey={(item) => item.date_created}
                            pagination={false}
                            loading={this.state.loadingPaymentDetails}/>
                    </div>
                </ConfigProvider>
            </Card>

            <Modal
                open={this.state.showPaymentDetailsModal}
                onCancel={() => this.closePaymentDetailsModal()}
                footer={<Space>
                    <Button onClick={() => this.closePaymentDetailsModal()}>Close</Button>
                    <Button type='primary' onClick={() => this.updatePaymentDetails()}>Add</Button>
                </Space>}
                title='Add Payment Details'>
                <Form layout='vertical'>
                    <Form.Item label='Payment currency'>
                        <Radio.Group onChange={(e) => this.setState({ paymentCurrency: e.target.value, paymentMethod: 'PAYPAL' })} value={this.state.paymentCurrency}>
                            <Radio value='USD'>USD (United States Dollar)</Radio>
                            <Radio value='EUR'>EUR (Euro)</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label='Payment method'>
                        <Radio.Group onChange={(e) => this.setState({ paymentMethod: e.target.value })} value={this.state.paymentMethod}>
                            <Radio value='PAYPAL'>PayPal</Radio>

                            {this.state.paymentCurrency === 'USD' ? <Radio value='ACH'>ACH (USD)</Radio> : ''}
                            {this.state.paymentCurrency === 'USD' ? <Radio value='SWIFT'>SWIFT (USD)</Radio> : ''}
                            {this.state.paymentCurrency === 'EUR' ? <Radio value='SWIFT'>Wire (EUR)</Radio> : ''}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item hidden={this.state.paymentMethod !== 'PAYPAL'}>
                        <Alert showIcon type='warning' message='PayPal fees & FX fees may apply.' />
                    </Form.Item>

                    <Form.Item label='PayPal email address' hidden={this.state.paymentMethod !== 'PAYPAL'}>
                        <Input type='email' value={this.state.paypalEmail} onChange={(e) => this.setState({ paypalEmail: e.target.value})} />
                    </Form.Item>

                    <Form.Item hidden={this.state.paymentMethod === 'PAYPAL'}>
                        <Alert showIcon type='warning' message='Wire in USD fee: USD 23.00; Wire in EUR fee: USD 17.50. 3% FX fee may apply. Intermediary bank fees may apply.' />
                    </Form.Item>



                    <Form.Item label='Full name of the account holder' hidden={this.state.paymentMethod === 'PAYPAL'}>
                        <Input value={this.state.accountName} onChange={(e) => this.setState({ accountName: e.target.value})} />
                    </Form.Item>
                    <Form.Item label='Billing email address' hidden={this.state.paymentMethod === 'PAYPAL'}>
                        <Input value={this.state.billingEmail} onChange={(e) => this.setState({ billingEmail: e.target.value})} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='IBAN / Account number' hidden={this.state.paymentMethod !== 'WIRE' && this.state.paymentMethod !== 'SWIFT'}>
                                <Input value={this.state.ibanAccountNo} onChange={(e) => this.setState({ ibanAccountNo: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='SWIFT / BIC code' hidden={this.state.paymentMethod !== 'WIRE' && this.state.paymentMethod !== 'SWIFT'}>
                                <Input value={this.state.swiftBic} onChange={(e) => this.setState({ swiftBic: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='ACH routing number' hidden={this.state.paymentMethod !== 'ACH' && this.state.paymentMethod !== 'WIRE'}>
                                <Input value={this.state.achRoutingNo} onChange={(e) => this.setState({ achRoutingNo: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Account number' hidden={this.state.paymentMethod !== 'ACH' && this.state.paymentMethod !== 'WIRE'}>
                                <Input value={this.state.achAccountNo} onChange={(e) => this.setState({ achAccountNo: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item label='Account type' hidden={this.state.paymentMethod !== 'ACH' && this.state.paymentMethod !== 'WIRE'}>
                        <Radio.Group onChange={(e) => this.setState({ achAccountType: e.target.value })} value={this.state.achAccountType}>
                            <Radio value='CHECKING'>Checking</Radio>
                            <Radio value='SAVINGS'>Savings</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {this.state.paymentMethod !== 'PAYPAL' ? <Divider plain>Recipient address</Divider> : ''}

                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Country' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Select
                                    placeholder='Select country'
                                    showSearch
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }}
                                    onChange={(value) => this.setState({ recipientCountry: value })}>
                                    {this.countries.map((key) => {
                                        return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Address' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.recipientAddress} onChange={(e) => this.setState({ recipientAddress: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='City' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.recipientCity} onChange={(e) => this.setState({ recipientCity: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Post code' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.recipientPostCode} onChange={(e) => this.setState({ recipientPostCode: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {this.state.paymentMethod !== 'PAYPAL' ? <Divider plain>Bank address</Divider> : ''}

                    <Form.Item label='Full name of the bank' hidden={this.state.paymentMethod === 'PAYPAL'}>
                        <Input value={this.state.bankName} onChange={(e) => this.setState({ bankName: e.target.value})} />
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Bank country' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Select
                                    placeholder='Select country'
                                    showSearch
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }}
                                    onChange={(value) => this.setState({ bankCountry: value })}>
                                    {this.countries.map((key) => {
                                        return <Option key={key.value} value={key.value}>{key.label}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Bank address' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.bankAddress} onChange={(e) => this.setState({ bankAddress: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Bank city' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.bankCity} onChange={(e) => this.setState({ bankCity: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Bank post code' hidden={this.state.paymentMethod === 'PAYPAL'}>
                                <Input value={this.state.bankPostCode} onChange={(e) => this.setState({ bankPostCode: e.target.value})} />
                            </Form.Item>
                        </Col>
                    </Row>



                </Form>
            </Modal>
        </Fragment>;
    }
}

export default connect(null, { updatePage, loadAffiliateBillingInfo,
    loadAffiliateBillingInvoices, downloadAffiliateInvoice, loadAffiliatePaymentDetails, updateAffiliatePaymentDetails
})(AffiliateBilling);