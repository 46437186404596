import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Form, Modal, Select, Radio, Row, Col, message, Button} from "antd";
import TextArea from "antd/es/input/TextArea";
import {submitCancellationRequest} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";
import AccessItem from "../permissions/AccessItem";

class CancelIPAddressesModal extends React.Component {
    state = {
        cancellationTime: 'END_OF_BILLING_CYCLE',
        cancellationReason: '',
        customCancellationReason: '',
        recommendationScore: 0,
        howLikely: 0,
        ipAddresses: [],
        submitting: false
    };

    submitRequest() {
        if(this.state.cancellationTime.trim() === '') {
            return message.error('Please select the cancellation time!');
        }

        if(this.props.selectedIPAddresses.length === 0) {
            return message.error('Please choose at least 1 IP address!');
        }

        if(this.state.cancellationReason === '') {
            return message.error('Please select a cancellation reason!');
        }

        if(this.state.customCancellationReason === '') {
            return message.error('Please provide a cancellation reason!');
        }

        if(this.state.recommendationScore === 0) {
            return message.error('Please provide a recommendation score!');
        }

        if(this.state.howLikely === 0) {
            return message.error('Please answer how likely are you to return in the future!');
        }

        let data = {
            cancellation_time: this.state.cancellationTime,
            cancellation_reason: this.state.cancellationReason,
            custom_cancellation_reason: this.state.customCancellationReason,
            recommendation_score: this.state.recommendationScore,
            ip_addresses: this.props.selectedIPAddresses,
            how_likely_to_use_us_again: this.state.howLikely,
        };

        this.setState({ submitting: true });

        this.props.submitCancellationRequest(data, () => {
            this.setState({ submitting: false });
            this.closeModal();

            message.success('Cancellation request successfully submitted!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ submitting: false });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal() {
        this.setState({
            cancellationTime: 'END_OF_BILLING_CYCLE',
            cancellationReason: '',
            customCancellationReason: '',
            recommendationScore: 0,
            howLikely: 0,
            ipAddresses: [],
            submitting: false
        });

        this.props.close();
    }

    render() {

        const { Option } = Select;

        return(
            <Modal
                open={this.props.show}
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={
                    <Fragment>
                        <Button onClick={() => this.closeModal()} disabled={this.state.submitting}>Close</Button>
                        <AccessItem scopes={['can_add_cancellation_requests']}>
                            <Button onClick={() => this.submitRequest()} disabled={this.state.submitting} loading={this.state.submitting} type='primary'>Submit</Button>
                        </AccessItem>
                    </Fragment>
                }
                title='Cancellation Request'>
                <p>Please let us know why you decided to cancel. Your feedback is very important to us and it helps us improve our services.</p>

                <Form layout='vertical'>
                    <Form.Item label='Reason:'>
                        <Select onChange={(value) => this.setState({ cancellationReason: value }) }>
                            <Option value='SWITCHED_PROVIDERS'>Switched to another service provider</Option>
                            <Option value='SERVICE_TOO_EXPENSIVE'>Service is too expensive</Option>
                            <Option value='NOT_HAPPY_WITH_SERVICE'>I'm not happy with the service</Option>
                            <Option value='TECHNICAL_ISSUES'>I experienced technical issues</Option>
                            <Option value='NOT_GOOD_VALUE'>I did not get enough value</Option>
                            <Option value='MISSING_FEATURES'>Some features that I needed are missing</Option>
                            <Option value='FINANCIAL_REASONS'>Experiencing financial issues right now</Option>
                            <Option value='PROJECT_ENDED'>Project ended</Option>
                            <Option value='OTHER'>Other</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Please describe your reason:'>
                        <TextArea onChange={(e) => this.setState({ customCancellationReason: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='How likely are you to recommend Priority Prospect to a friend or colleague?'>
                        <Row>
                            <Col xs={24}>
                                <Radio.Group style={{width: '100%', textAlign: 'center'}} onChange={(e) => this.setState({ recommendationScore: e.target.value })}>
                                    <Radio style={{width: '15%'}} value={1}>1</Radio>
                                    <Radio style={{width: '15%'}} value={2}>2</Radio>
                                    <Radio style={{width: '15%'}} value={3}>3</Radio>
                                    <Radio style={{width: '15%'}} value={4}>4</Radio>
                                    <Radio style={{width: '15%'}} value={5}>5</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{textAlign: 'left'}}>
                                <small>Not at all likely</small>
                            </Col>
                            <Col xs={12} style={{textAlign: 'right'}}>
                                <small>Extremely likely</small>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label='How likely are you to return to use our services in the future?'>
                        <Row>
                            <Col xs={24}>
                                <Radio.Group style={{width: '100%', textAlign: 'center'}} onChange={(e) => this.setState({ howLikely: e.target.value })}>
                                    <Radio style={{width: '15%'}} value={1}>1</Radio>
                                    <Radio style={{width: '15%'}} value={2}>2</Radio>
                                    <Radio style={{width: '15%'}} value={3}>3</Radio>
                                    <Radio style={{width: '15%'}} value={4}>4</Radio>
                                    <Radio style={{width: '15%'}} value={5}>5</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} style={{textAlign: 'left'}}>
                                <small>Not at all likely</small>
                            </Col>
                            <Col xs={12} style={{textAlign: 'right'}}>
                                <small>Extremely likely</small>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { submitCancellationRequest })(CancelIPAddressesModal);