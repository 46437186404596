import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, DatePicker, Row, Space, Table} from "antd";
import moment from 'moment';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';
import { Tooltip as ChartTooltip } from 'recharts';
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAffiliateReports} from "../../actions/affiliate";
import {MoneyField} from "../shared/MoneyField";

class AffiliateReports extends React.Component {
    state = {
        loading: false,
        data: [],
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    };

    componentDidMount() {
        this.props.updatePage('Reports');
        this.loadData();
    }

    updateDateRange(dates, dateStrings) {
        this.setState({
            startDate: dateStrings[0],
            endDate: dateStrings[1],
        });
    }

    loadData() {
        this.setState({ loading: true });

        this.props.loadAffiliateReports(this.state.startDate, this.state.endDate, (res) => {
            this.setState({ data: res.data, loading: false });
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { RangePicker } = DatePicker;

        const columns = [
            { title: 'Date', dataIndex: 'date' },
            { title: 'Referrals', align: 'center', dataIndex: 'referrals' },
            { title: 'Clicks', align: 'center', dataIndex: 'clicks' },
            { title: 'Earnings', align: 'center', dataIndex: 'earnings', render: (item) => <MoneyField amount={item} currency='USD' /> },
        ];

        return <Fragment>
            <Row gutter={[16, 16]}>
                <Col>
                    <Space>
                        <RangePicker
                            ranges={{
                                'Today': [moment(), moment()],
                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                                'Last 14 Days': [moment().subtract(14, 'days'), moment()],
                                'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                'Last 3 Months': [moment().subtract(3, 'month'), moment()],
                                'Last 6 Months': [moment().subtract(6, 'month'), moment()],
                                'This Year': [moment().startOf('year'), moment()],
                                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
                            }}
                            format="YYYY-MM-DD"
                            defaultValue={[moment(), moment()]}
                            disabled={this.state.loading}
                            onChange={(dates, dateStrings) => this.updateDateRange(dates, dateStrings)} />
                        <Button type='primary' onClick={() => this.loadData()} disabled={this.state.loading}
                                loading={this.state.loading}>Update</Button>
                    </Space>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div style={{ width: '100%', height: 250 }}>
                        <ResponsiveContainer>
                            <LineChart data={this.state.data}>
                                <Line dataKey="referrals" stroke="#d30082" fill="#d30082" />
                                <Line dataKey="clicks" stroke="#0f2463" fill="#0f2463" />
                                <Line dataKey="earnings" stroke="#32ba00" fill="#32ba00" />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <ChartTooltip formatter={(value, name) => {
                                    if(name === 'earnings') {
                                        return ['$' + value.toFixed(2), normalizeEnum(name)]
                                    } else {
                                        return [value, normalizeEnum(name)];
                                    }
                                }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div style={{overflowX: 'auto'}}>
                        <Table
                            dataSource={this.state.data}
                            columns={columns}
                            rowKey={(item) => item.date}
                            pagination={false}
                            loading={this.state.loading}/>
                    </div>
                </Col>
            </Row>
        </Fragment>;
    }
}

export default connect(null, { updatePage, loadAffiliateReports })(AffiliateReports);