import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {disableVat, loadVat} from "../../actions/account";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import {Button, Col, message, Popconfirm, Row, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined} from "@ant-design/icons";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddVatModal from "./AddVatModal";
import {StatusIndicator} from "../shared/Status";
import AccessItem from "../permissions/AccessItem";
import AccessGate from "../permissions/AccessGate";
import {hasPermission} from "../permissions/PermissionUtils";

class VATTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        data: [],
        loadingData: true,
        showAddVATModal: false
    };

    componentDidMount() {
        this.props.updatePage('VAT');
        this.loadVat();
    }

    loadVat(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingData: true });

        this.props.loadVat(this.props.companyGuid, page, per_page, (res) => {
            this.setState({ data: res.data.data, loadingData: false, pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingData: false });
                displayErrors(err.response.data);
            }
        });
    }

    disableVat(guid) {
        this.setState({ loadingData: true });

        this.props.disableVat(this.props.companyGuid, guid, () => {
            this.setState({ loadingData: true });
            message.success('VAT id successfully disabled!');

            this.loadVat();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingData: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadLogs(data.current, data.pageSize);
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'vat_id' },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Updated', dataIndex: 'date_updated', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-' },
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_vat'])) {
            columns.push({ align: 'right', render: (item, record) => {
                    if(record.status === 'ACTIVE') {
                        return <AccessItem scopes={['can_edit_vat']}>
                            <Popconfirm
                                onConfirm={() => this.disableVat(record.guid)}
                                placement='topRight'
                                title="Are you sure you want to disable it?"
                                okText="Disable"
                                okType='danger'
                                cancelText="Cancel">
                                <Button size='small' danger icon={<DeleteOutlined />}>Disable</Button>
                            </Popconfirm>
                        </AccessItem>;
                    }
                }});
        }

        return(
            <AccessGate scopes={['can_view_vat']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_vat']}>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => this.setState({showAddVATModal: true})}>Add VAT ID</Button>
                        </AccessItem>
                    </Col>
                    <Col><Tooltip title='Reload' placement='left'><Button onClick={() => this.loadVat()} type='text'
                                                                          icon={<UndoOutlined/>}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.guid}
                        dataSource={this.state.data}
                        loading={this.state.loadingData}
                        pagination={this.state.pagination}
                        onChange={(e) => this.changePage(e)}
                    />
                </div>

                    <AddVatModal
                        companyGuid={this.props.companyGuid}
                        showModal={this.state.showAddVATModal}
                        close={() => this.setState({showAddVATModal: false})}
                        reloadTable={() => this.loadVat()} guid={this.props.match.params.guid}/>
            </AccessGate>
    );
    }
    }

    const mapStateToProps = state => ({
    company: state.auth.user.user.company
});


export default connect(mapStateToProps, { updatePage, loadVat, disableVat })(VATTable);