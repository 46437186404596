import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {deleteContact, loadCompanyContacts, resendVerification} from "../../actions/account";
import AddCompanyContactModal from "./AddCompanyContactModal";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {EditOutlined, MailOutlined} from "@ant-design/icons";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditCompanyContactModal from "./EditCompanyContactModal";
import AccessGate from "../permissions/AccessGate";
import AccessItem from "../permissions/AccessItem";
import {hasPermission} from "../permissions/PermissionUtils";

class CompanyContacts extends React.Component {
    state = {
        contacts: [],
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        showAddContactModal: false,
        showEditContactModal: false,
        activeContact: ''
    };

    componentDidMount() {
        this.props.updatePage('Contacts');
        this.loadContacts();
    }

    loadContacts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadCompanyContacts(this.props.companyGuid, page, per_page,(res) => {
            this.setState({ contacts: res.data.data, pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, loading: false });
        }, (err) => {
            this.setState({ loading: false});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadContacts(data.current, data.pageSize);
    }

    resendVerification(guid) {
        this.setState({ loading: true });

        this.props.resendVerification(this.props.companyGuid, guid, () => {
            this.setState({ loading: false });
            message.success('Verification email was successfully resent!');
        }, (err) => {
            this.setState({ loading: false});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteContact(guid) {
        this.setState({ loading: true });

        this.props.deleteContact(this.props.companyGuid, guid, () => {
            this.setState({ loading: false });
            message.success('Contact successfully deleted!');
            this.loadContacts();
        }, (err) => {
            this.setState({ loading: false});

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'First name', dataIndex: 'first_name' },
            { title: 'Last name', dataIndex: 'last_name' },
            { title: 'Email address', dataIndex: 'email_address' },
            { title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator status={item} el='badge'  /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> }
        ];

        if(hasPermission(this.props.company.permissions, ['can_edit_company_contacts'])) {
            columns.push({ align: 'right', render: (item, record) => {
                    if(record.status === 'VERIFICATION')
                    {
                        return <Button onClick={() => this.resendVerification(record.guid)} size='small' icon={<MailOutlined />}>Re-send Email</Button>
                    }
                    else if(record.status === 'ACTIVE' || record.status === 'VERIFICATION')
                    {
                        return <Space>
                            {record.can_be_disabled ? <Popconfirm
                                title="Are you sure you want to delete this contact?"
                                onConfirm={() => this.deleteContact(record.guid)}
                                okText='Delete'
                                placement='topRight'
                                okButtonProps={{danger: true}}
                                cancelText='Cancel'>
                                <Button size='small' danger icon={<DeleteOutlined />} />
                            </Popconfirm> : ''}
                            <Button onClick={() => this.setState({ activeContact: record.guid, showEditContactModal: true })} size='small' icon={<EditOutlined />}>Edit</Button>
                        </Space>;
                    }
                }});
        }

        return(
            <AccessGate scopes={['can_view_company_contacts']}>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <AccessItem scopes={['can_add_company_contacts']}>
                            <Button type='primary' icon={<PlusOutlined/>}
                                    onClick={() => this.setState({showAddContactModal: true})}>Add Contact</Button>
                        </AccessItem>
                    </Col>
                    <Col>
                        <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadContacts()}
                                                                               type='text'
                                                                               icon={<UndoOutlined/>}/></Tooltip>
                    </Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        dataSource={this.state.contacts}
                        columns={columns}
                        rowKey={(item) => item.guid}
                        pagination={this.state.pagination}
                        onChange={(e) => this.changePage(e)}
                        loading={this.state.loading}/>
                </div>

                    <AddCompanyContactModal
                        guid={this.props.companyGuid}
                        reloadContacts={() => this.loadContacts()}
                        showModal={this.state.showAddContactModal}
                        close={() => this.setState({showAddContactModal: false})}/>

                    <EditCompanyContactModal
                        showModal={this.state.showEditContactModal}
                        companyGuid={this.props.companyGuid}
                        activeContact={this.state.activeContact}
                        close={() => this.setState({showEditContactModal: false})}/>
            </AccessGate>
    );
    }
    }

    const mapStateToProps = state => ({
    company: state.auth.user.user.company
});

export default connect(mapStateToProps, { updatePage, loadCompanyContacts, resendVerification, deleteContact })(CompanyContacts);