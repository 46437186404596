import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, Row, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {downloadInvoice, loadInvoices} from "../../actions/billing";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";
import {Link} from "react-router-dom";
import {EyeOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import {MoneyField} from "../shared/MoneyField";
import AccessGate from "../permissions/AccessGate";

class InvoicesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        invoices: [],
        loadingInvoices: true
    }

    componentDidMount() {
        this.props.updatePage('Invoices');
        this.loadInvoices();
    }

    loadInvoices(page = null, per_page = null) {
        if(per_page !== this.state.pagination.pageSize) {
            page = 1;
        }

        if(page == null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingInvoices: true });

        this.props.loadInvoices(page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ invoices: res.data.data, loadingInvoices: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoices: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Invoice ID', dataIndex: 'id', render: (item) => <strong><Link to={'/billing/invoices/' + item}>#{item}</Link></strong> },
            { title: 'Amount', align: 'center', dataIndex: 'initial_amount', render: (item, record) => <MoneyField amount={record.initial_amount} currency={record.currency} />},
            { title: 'Tax', align: 'center', dataIndex: 'initial_tax', render: (item, record) => <MoneyField amount={record.initial_tax} currency={record.currency} />},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />,
                filters: [
                    { text: 'Unpaid', value: 'UNPAID' },
                    { text: 'Paid', value: 'PAID' },
                    { text: 'Partially Paid', value: 'PARTIALLY_PAID' },
                    { text: 'Cancelled', value: 'CANCELLED' },
                ],
                defaultFilteredValue: ['UNPAID', 'PAID', 'PARTIALLY_PAID']
            },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: 'Due', align: 'center', dataIndex: 'date_due', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: '', align: 'right', render: (item, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.props.history.push('/billing/invoices/' + record.id)}>View</Button>}
        ];

        return(
            <AccessGate scopes={['can_view_invoices']}>
                <Row justify='end'>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadInvoices()}/></Tooltip></Col>
                </Row>
                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.id}
                        dataSource={this.state.invoices}
                        loading={this.state.loadingInvoices}
                        pagination={this.state.pagination}
                        onChange={(pagination) => this.loadInvoices(pagination.current, pagination.pageSize)}
                    />
                </div>
            </AccessGate>
    )
    }
    }

    export default connect(null, { updatePage, loadInvoices, downloadInvoice })(InvoicesTable);