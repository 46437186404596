import {driver} from "driver.js";

const initialState = {
    driver: driver(),
    showDriver: true,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case 'UPDATE_DRIVER_HIGHLIGHT':
            state.driver.highlight(action.payload);

            return {
                ...state,
            };
        case 'UPDATE_DRIVER_MULTIPLE_HIGHLIGHTS':
            let _driver = driver({
                steps: action.payload
            });
            _driver.drive();

            return {
                ...state,
                driver: _driver,
            };
        case 'NEXT_STEP_DRIVER':
            state.driver.moveNext();

            return {
                ...state,
            };
        case 'PREVIOUS_STEP_DRIVER':
        case 'DISABLE_DRIVER':
            state.driver.destroy();

            return {
                ...state,
                driver: driver(),
            }
        default:
            return state;
    }
}