import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Row, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {loadCompanies, makeCompanyDefault} from "../../actions/account";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {Link} from "react-router-dom";
import {loadUser} from "../../actions/auth";
import AddCompanyModal from "./AddCompanyModal";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {StarFilled} from "@ant-design/icons";

class Companies extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        companies: [],
        loadingCompanies: true,
        showAddCompanyModal: false,
        showEditCompanyModal: this.props.activeCompany !== null
    };

    componentDidMount() {
        this.props.updatePage('Companies');
        this.loadCompanies();
    }

    loadCompanies(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingCompanies: true });

        this.props.loadCompanies(page, per_page, null, (res) => {
            this.setState({ companies: res.data.data, loadingCompanies: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompanies: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadCompanies(data.current, data.pageSize);
    }

    closeCreateCompanyModal(reload = false) {
        this.setState({ showAddCompanyModal: false });

        if(reload) {
            this.loadCompanies();
        }
    }

    render() {

        const columns = [
            { title: 'Company', dataIndex: 'company', key: 'company', render: (item, row) => {

                let activeCompany = '';
                if(this.props.user.user.company.guid === row.company.guid) {
                    activeCompany = <span> <Tooltip title='Active company!'><StarFilled style={{color: 'gold'}} /></Tooltip></span>;
                }

                if(row.account_type !== 'STAFF') {
                    return <strong><Link to={'/company/' + row.company.guid + '/profile'}>{row.company.name}</Link>{activeCompany}</strong>;
                }

                return <span>{row.company.name}{activeCompany}</span>;
            }},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator el='badge' status={item} /> },
            { title: 'Type', dataIndex: 'account_type', align: 'center', render: (item) => normalizeEnum(item) },
            { title: '', align: 'right',
                render: (item, row) => row.account_type === 'OWNER' || row.account_type === 'ADMIN' ? <Link to={'/account/company/' + row.company.guid + '/profile'}>
                    <Button size='small' icon={<EditOutlined />}>Manage Company</Button> </Link> : ''}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{paddingBottom: '10px'}}>
                    <Col><Button type='primary' icon={<PlusOutlined/>}
                                 onClick={() => this.setState({showAddCompanyModal: true})}>Add Company</Button></Col>
                    <Col><Tooltip title='Reload table'><Button type='text' icon={<UndoOutlined/>}
                                                               onClick={() => this.loadCompanies()}/></Tooltip></Col>
                </Row>

                <div style={{overflowX: 'auto'}}>
                    <Table
                        columns={columns}
                        rowKey={(item) => item.company.guid}
                        dataSource={this.state.companies}
                        loading={this.state.loadingCompanies}
                        pagination={this.state.pagination}
                        onChange={(e) => this.changePage(e)}
                    />
                </div>

                    <AddCompanyModal
                        showModal={this.state.showAddCompanyModal}
                        closeModal={(reload) => this.closeCreateCompanyModal(reload)}/>
            </Fragment>
    );
    }
    }

    const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { updatePage, loadCompanies, loadUser, makeCompanyDefault })(Companies);